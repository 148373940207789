import { HttpClient } from '../common/httpClient';
import { MetaAccount, MetaChannel, PhoneNumber } from '../../types/meta/metaAccount';
import { PageResponse } from '../common/pagingModel';
import dateTimeService from '../../service/dateTimeService';

type MetaAccountResponse = {
  readonly businessAccountId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly isDisabled: boolean;
  readonly channel: string;
  readonly name: string;
  readonly appId: string;
  readonly metaAppId: string;
  readonly accessToken?: string;
  readonly webhookVerifyToken?: string;
  readonly tenantId: string;
  readonly phoneNumbers: PhoneNumber[];
};

export class MetaAccountApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToMetaAccount(response: MetaAccountResponse): MetaAccount {
    return {
      appId: response.appId,
      channel: response.channel as MetaChannel,
      name: response.name,
      businessAccountId: response.businessAccountId,
      isDisabled: response.isDisabled,
      metaAppId: response.metaAppId,
      tenantId: response.tenantId,
      createdAt: dateTimeService.convertIsoDateToTimestamp(response.createdAt),
      updatedAt: dateTimeService.convertIsoDateToTimestamp(response.updatedAt),
      phoneNumbers: response.phoneNumbers,
    };
  }

  getAll(): Promise<MetaAccount[]> {
    return this.httpClient.get<PageResponse<MetaAccountResponse>>(`accounts`)
      .then(data => (
        data.content.map(it => this.mapToMetaAccount(it))
      ));
  }

  getAllByTenantId(tenantId: string): Promise<MetaAccount[]> {
    return this.httpClient.get<PageResponse<MetaAccountResponse>>(`tenants/${tenantId}/accounts`)
      .then(data => (
        data.content.map(it => this.mapToMetaAccount(it))
      ));
  }

  getDefault(): Promise<MetaAccount> {
    return this.httpClient.get<MetaAccountResponse>(`tenants/accounts/default`)
      .then(data => this.mapToMetaAccount(data));
  }

  getWhatsappByTenantId(tenantId: string): Promise<MetaAccount[]> {
    return this.getAllByTenantId(tenantId).then(metaAccounts => (
      metaAccounts.filter(it => it.channel === MetaChannel.WHATSAPP)
    ));
  }

  async add(values: MetaAccount): Promise<void> {
    await this.httpClient.post<MetaAccount>(`accounts`, values);
  }

  getById(businessAccountId: string): Promise<MetaAccount> {
    return this.httpClient.get<MetaAccountResponse>(`accounts/${businessAccountId}`)
      .then(data => this.mapToMetaAccount(data));
  }

  async update(businessAccountId: string, item: MetaAccount): Promise<void> {
    await this.httpClient.put<MetaAccount>(`accounts/${businessAccountId}`, item);
  }

  async enable(businessAccountId: string): Promise<void> {
    await this.httpClient.put<MetaAccount>(`accounts/${businessAccountId}/enable`, {});
  }

  async disable(businessAccountId: string): Promise<void> {
    await this.httpClient.put<MetaAccount>(`accounts/${businessAccountId}/disable`, {});
  }

  async delete(businessAccountId: string): Promise<void> {
    await this.httpClient.delete<MetaAccount>(`accounts/${businessAccountId}`);
  }
}
