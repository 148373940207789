import { AnyAction } from 'redux';
import { GetWhatsappPricing, WhatsappContactsWithoutPricing, WhatsappCountry } from '../types/meta/whatsappPricing';
import { MetaAccount } from '../types/meta/metaAccount';

export const tabs = { config: 0, pricing: 1 };

type CustomerOption = {
  readonly value: string;
  readonly label: string;
  readonly currency: string;
};

interface WhatsappState {
  tab: number;
  tabOpen: boolean;
  configs: MetaAccount[];
  pricings: GetWhatsappPricing[];
  pricingsLoading: boolean;
  config: Partial<MetaAccount>;
  selectedWhatsappPricing: Partial<GetWhatsappPricing>;
  pricingLoading: boolean;
  configsLoading: boolean;
  selectedCustomer: CustomerOption;
  whatsappMonthlyFee: { fee: number };
  countries: WhatsappCountry[];
  selectedWhatsappConfig: Partial<MetaAccount>,
  contactsWithoutPricing: WhatsappContactsWithoutPricing;
  contactsWithoutPricingLoading: boolean;
}

const initialState: WhatsappState = {
  tab: tabs.config,
  tabOpen: false,
  configs: [],
  pricings: [],
  pricingsLoading: false,
  config: {},
  selectedWhatsappPricing: {},
  pricingLoading: false,
  configsLoading: false,
  selectedCustomer: { value: '', label: '', currency: '' },
  whatsappMonthlyFee: { fee: 0 },
  countries: [],
  selectedWhatsappConfig: {},
  contactsWithoutPricing: { phoneNumbers: 0, countries: [] },
  contactsWithoutPricingLoading: false,
};

export default function reducer(state: WhatsappState = initialState, action: AnyAction): WhatsappState {
  switch (action.type) {
    case 'SET_WA_ADMINISTRATION_TAB': {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case 'SET_WA_ADMINISTRATION_TAB_OPEN': {
      return {
        ...state,
        tabOpen: action.tabOpen,
      };
    }
    case 'SET_WA_ADMINISTRATION_CONFIGS': {
      return {
        ...state,
        configs: action.configs,
      };
    }
    case 'SET_WHATSAPP_ADMIN_PRICINGS': {
      return {
        ...state,
        pricings: action.pricings,
      };
    }
    case 'SET_WHATSAPP_ADMIN_PRICINGS_LOADING': {
      return {
        ...state,
        pricingsLoading: action.payload,
      };
    }
    case 'SET_WA_ADMINISTRATION_CONFIG': {
      return {
        ...state,
        config: action.config,
      };
    }
    case 'SET_WHATSAPP_ADMIN_PRICING_LOADING': {
      return {
        ...state,
        pricingLoading: action.payload,
      };
    }
    case 'SET_SELECTED_CUSTOMER': {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }
    case 'SET_WHATSAPP_MONTHLY_FEE': {
      return {
        ...state,
        whatsappMonthlyFee: action.payload,
      };
    }
    case 'SET_WHATSAPP_COUNTRIES': {
      return {
        ...state,
        countries: action.payload,
      };
    }
    case 'SET_SELECTED_WHATSAPP_CONFIG': {
      return {
        ...state,
        selectedWhatsappConfig: action.payload,
      };
    }
    case 'SET_SELECTED_WHATSAPP_PRICING': {
      return {
        ...state,
        selectedWhatsappPricing: action.payload,
      };
    }
    case 'UPDATE_WHATSAPP_PRICING': {
      const itemToReplace = {
        id: action.payload.id,
        breakout: action.payload.breakout,
        utility: action.payload.utility,
        country: action.payload.country,
        authentication: action.payload.authentication,
        marketing: action.payload.marketing,
        service: action.payload.service,
      };
      return {
        ...state,
        pricings: [
          ...state.pricings.filter((item) => item.id !== action.payload.id),
          itemToReplace,
        ],
      };
    }
    case 'SET_WA_CONFIGS_LOADING': {
      return {
        ...state,
        configsLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_CONTACTS_WITHOUT_PRICING': {
      return {
        ...state,
        contactsWithoutPricing: action.payload,
      };
    }
    case 'SET_WHATSAPP_CONTACTS_WITHOUT_PRICING_LOADING': {
      return {
        ...state,
        contactsWithoutPricingLoading: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
