import { AnyAction } from 'redux';
import { Campaign } from '../types/campaign';

interface CampaignHistoryState {
  campaigns: Campaign[];
  selectedCampaign: $TSFixMe;
  campaignLoading: boolean;
  campaignsLoading: boolean;
  attachment: {
    uploading: boolean;
    data?: $TSFixMe;
    id?: $TSFixMe;
    link?: string;
    mpId?: $TSFixMe;
    validSize: boolean;
    failed: boolean;
    source?: $TSFixMe;
    error?: $TSFixMe;
    name?: string;
  },
  noAddedFileError: boolean;
  testMessagesLoaded: boolean;
  campaignWithoutPricingDialogOpen: boolean;
}

const initialState: CampaignHistoryState = {
  campaigns: [],
  selectedCampaign: {},
  campaignLoading: false,
  campaignsLoading: false,
  attachment: {
    uploading: false,
    data: undefined,
    id: undefined,
    validSize: true,
    failed: false,
    source: null,
    name: undefined,
  },
  noAddedFileError: false,
  testMessagesLoaded: false,
  campaignWithoutPricingDialogOpen: false,
};

export default function reducer(state: CampaignHistoryState = initialState, action: AnyAction): CampaignHistoryState {
  switch (action.type) {
    case 'SET_CAMPAIGNS_LOADING': {
      return {
        ...state,
        campaignsLoading: action.payload,
      };
    }
    case 'SET_CAMPAIGNS': {
      return {
        ...state,
        campaigns: action.payload,
      };
    }
    case 'SET_CAMPAIGN_LOADING': {
      return {
        ...state,
        campaignLoading: action.payload,
      };
    }
    case 'REMOVE_CAMPAIGN': {
      return {
        ...state,
        campaigns: state.campaigns.filter((item) => item.id !== action.payload),
      };
    }

    case 'SET_CAMPAIGN': {
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    }
    case 'SET_CAMPAIGN_FILE': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          data: action.payload,
          //validSize: validSize(action.payload.size, state.payloadType),
        },
      };
    }
    case 'SET_CAMPAIGN_FILE_UPLOADING': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          uploading: true,
        },
      };
    }
    case 'SET_CAMPAIGN_FILE_UPLOADED': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          uploading: false,
          id: action.payload.id,
          link: action.payload.link,
          name: action.payload.name,
        },
      };
    }
    case 'RESET_CAMPAIGN_FILE_UPLOADED': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          uploading: false,
          data: undefined,
          id: undefined,
          link: undefined,
          name: undefined,
        },
      };
    }
    case 'SET_NO_ADDED_FILE_ERROR': {
      return {
        ...state,
        noAddedFileError: action.payload,
      };
    }
    case 'SET_TEST_MESSAGES_LOADED': {
      return {
        ...state,
        testMessagesLoaded: action.payload,
      };
    }
    case 'SET_CAMPAIGN_WITHOUT_PRICING_DIALOG_OPEN': {
      return {
        ...state,
        campaignWithoutPricingDialogOpen: action.payload,
      };
    }
    
    default:
      return {
        ...state,
      };
  }
}
