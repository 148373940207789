import smsChatConfig from './smsChatConfig';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatListener, ChatListenerCallbacks } from '../interface';
import { ChatWsMessageType } from '../websocket/chatWsWrapper';
import { ChatMessagePayloadType, ChatMessageStatus } from '../../types/chatMessage';
import dateTimeService from '../../service/dateTimeService';
import {
  ChatProxyWsEvent,
  ChatProxyWsIncomingMessage,
  ChatProxyWsOutgoingMessage,
} from '../websocket/chatProxyWsMessage';
import chatProxyWsConnection from '../websocket/chatProxyWsConnection';

export default class SmsChatListener implements ChatListener {
  private registeredMessageListeners: string[] = [];

  register(callbacks: ChatListenerCallbacks): void {
    this.registeredMessageListeners = [
      chatProxyWsConnection.addMessageListener({
        channel: SupportedChatChannel.SMS,
        msgType: ChatWsMessageType.EVENT,
        onWsMessage: wsMessage => this.onSmsEvent(wsMessage, callbacks),
      }),
      chatProxyWsConnection.addMessageListener({
        channel: SupportedChatChannel.SMS,
        msgType: ChatWsMessageType.INCOMING_MESSAGE,
        onWsMessage: wsMessage => this.onSmsIncomingMessage(wsMessage, callbacks),
      }),
      chatProxyWsConnection.addMessageListener({
        channel: SupportedChatChannel.SMS,
        msgType: ChatWsMessageType.OUTGOING_MESSAGE,
        onWsMessage: wsMessage => this.onSmsOutgoingMessage(wsMessage, callbacks),
      }),
    ];
  }

  unregister(): void {
    this.registeredMessageListeners.forEach(listenerId => {
      chatProxyWsConnection.removeMessageListener(listenerId);
    });
  }

  private onSmsEvent(wsMessage: ChatProxyWsEvent, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onEvent?.({
      channel: SupportedChatChannel.SMS,
      msgId: payload.msgId,
      status: payload.status,
      timestamp: payload.timestamp,
    });
  }

  private onSmsIncomingMessage(wsMessage: ChatProxyWsIncomingMessage, callbacks: ChatListenerCallbacks): void {
    const { payload: msg } = wsMessage;

    callbacks.onIncomingMessage?.({
      channel: SupportedChatChannel.SMS,
      senderId: msg.accountId,
      clientId: msg.destination,
      msgId: msg.uuid,
      message: msg.payload.text,
      date: dateTimeService.formatCurrentTimestamp(smsChatConfig.dateFormat),
      outgoing: false,
      status: ChatMessageStatus.UNREAD,
      payloadType: ChatMessagePayloadType.TEXT,
      attachments: [],
      statusCode: 200,
      error: null,
    });
  }

  private onSmsOutgoingMessage(wsMessage: ChatProxyWsOutgoingMessage, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onOutgoingMessage?.({
      channel: SupportedChatChannel.SMS,
      senderId: payload.accountId,
      clientId: payload.destination,
      msgId: payload.messageId,
      message: payload.message,
      date: dateTimeService.formatCurrentTimestamp(smsChatConfig.dateFormat),
      outgoing: true,
      status: ChatMessageStatus.QUEUED,
      payloadType: ChatMessagePayloadType.TEXT,
      attachments: [],
      statusCode: 200,
      error: null,
    });
  }
}
