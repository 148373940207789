const translatedStatuses: Record<string, string> = {
  'ACCEPTED': 'status.accepted',
  'ACK': 'status.ack',
  'BLOCKED_NOT_IN_PACKAGE': 'status.blocked.not.in.package',
  'BLOCKED_OVER_CREDIT_LIMIT': 'status.blocked.over.credit.limit',
  'CALLED': 'status.called',
  'DELETED': 'status.deleted',
  'DELIVERED': 'status.delivered',
  'DEMO_LIMIT_EXCEEDED': 'status.demo.limit.exceeded',
  'ENROUTE': 'status.enroute',
  'ERROR': 'status.error',
  'EXPIRED': 'status.expired',
  'FAILED': 'status.failed',
  'LOGONLY': 'status.logonly',
  'NEW': 'status.new',
  'NOT_WHITELISTED': 'status.not.whitelisted',
  'OPT_OUT': 'status.opt.out',
  'PARTIALLY_DELIVERED': 'status.partially.delivered',
  'PREPARED': 'status.prepared',
  'READ': 'status.read',
  'REJECTED': 'status.rejected',
  'SENT': 'status.sent',
  'SENT_NO_FINAL_DLR': 'status.sent.no.final.dlr',
  'SENT_UNDELIVERED': 'status.sent.undelivered',
  'SPAM': 'status.spam',
  'UNDELIVERED': 'status.undelivered',
  'UNKNOWN': 'status.unknown',
};

export default translatedStatuses;