import { Contact } from '../../types/contact';
import { GetWhatsappPricing, PostWhatsappPricing, WhatsappCountry, WhatsappMonthlyFee } from '../../types/meta/whatsappPricing';
import { HttpClient } from '../common/httpClient';

type GetWhatsappPricingResponse = {
  readonly id: string;
  readonly country: { callingCode: number };
  readonly breakout: string;
  readonly marketingPrice: number;
  readonly utilityPrice: number;
  readonly authenticationPrice: number;
  readonly servicePrice: number;
  readonly editReason?: string;
  readonly isBlocked?: boolean;
  readonly startDate?: Date;
  readonly endDate?: Date; 
};

type WhatsappMonthlyFeeResponse = {
  readonly fee: number;
};

type WhatsappCountryResponse = {
  readonly dialCode: string;
  readonly name: string;
  readonly code: string;
  readonly country?: { callingCode: number | string };
};

type WhatsappContactsWithoutPricing = {
  readonly phoneNumbers: number;
  readonly countries: string[];
};

export class WhatsappPricingApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToGetWhatsappPricing(response: GetWhatsappPricingResponse):GetWhatsappPricing {
    return {
      id: response.id,
      country: response.country,
      marketing: response.marketingPrice,
      utility: response.utilityPrice,
      authentication: response.authenticationPrice,
      service: response.servicePrice,
      startDate: response.startDate,
      endDate: response.endDate,
      editReason: response.editReason,
      isBlocked: response.isBlocked,
    };
  }

  private mapToWhatsappMonthlyFee(response: WhatsappMonthlyFeeResponse): WhatsappMonthlyFee {
    return {
      fee: response.fee,
    };
  }

  private mapToWhatsappCountry(response: WhatsappCountryResponse): WhatsappCountry {
    return {
      dialCode: response.dialCode,
      name: response.name,
      code: response.code,
      country: response.country,
    };
  }


  private mapToWhatsappContactsWithoutPricing(response: WhatsappContactsWithoutPricing): WhatsappContactsWithoutPricing {
    return {
      phoneNumbers: response.phoneNumbers,
      countries: response.countries,
    };
  }

  getAll(businessAccountId: string): Promise<GetWhatsappPricing[]> {
    return this.httpClient.get<GetWhatsappPricingResponse[]>(`pricing/${businessAccountId}`)
      .then(data => (
        data.map(it => this.mapToGetWhatsappPricing(it))
      ));
  }

  getMonthlyFee(tenantId: string): Promise<WhatsappMonthlyFee> {
    return this.httpClient.get<WhatsappMonthlyFeeResponse>(`price-list/${tenantId}/monthly-fee`)
    .then(data => this.mapToWhatsappMonthlyFee(data));
  }

  getCountries(): Promise<WhatsappCountry[]> {
    return this.httpClient.get<WhatsappCountry[]>(`price-list/countries`)
      .then(data => (
        data.map(it => this.mapToWhatsappCountry(it))
      ));
  }

  async updateMonthlyFee(tenantId: string, fee: number): Promise<void> {
    await this.httpClient.put<WhatsappMonthlyFee>(`price-list/${tenantId}/monthly-fee`, { fee });
  }

  async add(businessAccountId: string, values: PostWhatsappPricing): Promise<void> {
    await this.httpClient.post<PostWhatsappPricing>(`pricing/${businessAccountId}/price`, values);
  }

  async update(businessAccountId: string, id: string, item: PostWhatsappPricing): Promise<void> {
    await this.httpClient.put<PostWhatsappPricing>(`pricing/${businessAccountId}/price/${id}`, item);
  }

  async delete(tenantId: string, id: string): Promise<void> {
    await this.httpClient.delete<GetWhatsappPricing>(`price-list/${tenantId}/entry/${id}`);
  }

  getContactsWithoutPricing(businessAccountId: number, values: Contact[]): Promise<WhatsappContactsWithoutPricing> {
    return this.httpClient.post<WhatsappContactsWithoutPricing>(`pricing/${businessAccountId}/verification`, { contacts: values })
      .then(data => this.mapToWhatsappContactsWithoutPricing(data));
  }

  async blockCountry(businessAccountId: string, country: number): Promise<void> {
    await this.httpClient.post<PostWhatsappPricing>(`pricing/${businessAccountId}/country/banned/${country}`, {});
  }

  async unblockCountry(businessAccountId: string, country: number): Promise<void> {
    await this.httpClient.delete<GetWhatsappPricing>(`pricing/${businessAccountId}/country/banned/${country}`);
  }
}
