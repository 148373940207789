import { v4 as uuidv4 } from 'uuid';
import chatProxyWsConnection from '../websocket/chatProxyWsConnection';
import dateTimeService from '../../service/dateTimeService';
import smsChatConfig from './smsChatConfig';
import { ChatPublisher, SendChatMessageParams } from '../interface';
import { ChatMessage, ChatMessagePayloadType, ChatMessageStatus } from '../../types/chatMessage';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatProxyOutgoingMessage } from '../websocket/chatProxyWsMessage';

export default class SmsChatPublisher implements ChatPublisher {
  async sendChatMessage(params: SendChatMessageParams): Promise<ChatMessage> {
    const message = this.createOutgoingMessage(params);

    return new Promise((resolve, reject) => {
      try {
        chatProxyWsConnection.sendMessage(message);

        resolve(this.convertToChatMessage(message, params));
      } catch (err) {
        reject(err);
      }
    });
  }

  private createOutgoingMessage(params: SendChatMessageParams): ChatProxyOutgoingMessage {
    return {
      channelType: SupportedChatChannel.SMS,
      accountId: params.senderId,
      targetId: params.clientId,
      destination: params.clientPhoneNumber,
      payloadType: ChatMessagePayloadType.TEXT,
      messageId: uuidv4(),
      message: params.text,
    };
  }

  private convertToChatMessage(message: ChatProxyOutgoingMessage, params: SendChatMessageParams): ChatMessage {
    return {
      channel: SupportedChatChannel.SMS,
      senderId: params.senderId,
      clientId: params.clientId,
      msgId: message.messageId,
      message: params.text,
      date: dateTimeService.formatCurrentTimestamp(smsChatConfig.dateFormat),
      outgoing: true,
      payloadType: ChatMessagePayloadType.TEXT,
      attachments: [],
      status: ChatMessageStatus.SENDING,
      statusCode: 200,
      error: null,
    };
  }
}
