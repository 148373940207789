import metaApi from '../../api/meta/metaApi';
import whatsappChatConfig from './whatsappChatConfig';
import { ChatApi, FetchChatMessagesRequest } from '../interface';
import { ChatSender } from '../../types/chatSender';
import { ChatClient, ChatClientType } from '../../types/chatClient';
import { MetaTarget } from '../../types/meta/metaTarget';
import {
  ChatAttachment,
  ChatConversationId,
  ChatMessage,
  ChatMessagePayloadType,
  ChatMessagesPage,
} from '../../types/chatMessage';
import { Page } from '../../types/page';
import { MetaMessage, MetaMessageType } from '../../types/meta/metaMessage';
import { SupportedChatChannel } from '../supportedChatChannel';
import dateTimeService from '../../service/dateTimeService';
import { MetaAccount, PhoneNumber } from '../../types/meta/metaAccount';

export default class WhatsappChatApi implements ChatApi {
  private readonly msgTypeMappingTable = {
    [MetaMessageType.TEXT]: ChatMessagePayloadType.TEXT,
    [MetaMessageType.DOCUMENT]: ChatMessagePayloadType.DOC,
    [MetaMessageType.IMAGE]: ChatMessagePayloadType.IMAGE,
    [MetaMessageType.VIDEO]: ChatMessagePayloadType.VIDEO,
    [MetaMessageType.LOCATION]: ChatMessagePayloadType.UNSUPPORTED,
    [MetaMessageType.BUTTON]: ChatMessagePayloadType.BUTTON,
    [MetaMessageType.INTERACTIVE]: ChatMessagePayloadType.UNSUPPORTED,
    [MetaMessageType.REACTION]: ChatMessagePayloadType.UNSUPPORTED,
    [MetaMessageType.TEMPLATE_WHATSAPP]: ChatMessagePayloadType.UNSUPPORTED,
    [MetaMessageType.TEMPLATE_MESSENGER]: ChatMessagePayloadType.UNSUPPORTED,
  };

  isEnabled(): Promise<boolean> {
    return Promise.resolve(true);
  }

  async fetchSenders(customerUuid: string): Promise<ChatSender[]> {
    const whatsappAccount: MetaAccount = await metaApi.accounts.getDefault();
    const whatsappPhoneNumbers: PhoneNumber[] = await metaApi.whatsappPhoneNumber.getPhoneNumbersById(whatsappAccount.businessAccountId);

    return whatsappPhoneNumbers
      .map(phoneNumber => this.mapWhatsappPhoneNumberToChatSender(phoneNumber));
  }

  private mapWhatsappPhoneNumberToChatSender(phoneNumber: PhoneNumber): ChatSender {
    return {
      id: phoneNumber.externalId,
      name: `${phoneNumber.name}, ${phoneNumber.phoneNumber}`,
    };
  }

  async fetchClients(senderId: string): Promise<ChatClient[]> {
    const metaTargets: MetaTarget[] = await metaApi.targets.getAllByPhoneNumberId(senderId);

    return metaTargets.map(target => ({
      channel: SupportedChatChannel.WHATSAPP,
      id: target.id,
      type: ChatClientType.NUMERIC,
      number: target.phoneNumber,
      lastActivityTime: target.lastReceivedMessageAt,
      senderId,
      foundInContacts: false,
    }));
  }

  async fetchMessages(client: ChatClient, params: FetchChatMessagesRequest): Promise<ChatMessagesPage> {
    const { senderId: whatsappBusinessId, id: targetId } = client;
    const metaMessagePage: Page<MetaMessage> = await metaApi.messages.getPageByTarget({
      whatsappBusinessId,
      targetId,
      page: params.page,
      size: params.size,
      sort: 'createdAt',
      direction: 'desc',
    });

    const content = metaMessagePage.content
      .map(it => this.mapMetaMessageToChatMessage(whatsappBusinessId, targetId, it))
      .reverse();

    return {
      content,
      pageNumber: metaMessagePage.pageNumber,
      pageSize: metaMessagePage.pageSize,
      totalElements: metaMessagePage.totalElements,
      totalPages: metaMessagePage.totalPages,
    };
  }

  async fetchUnreadConversationsIds(customerUuid: string): Promise<ChatConversationId[]> {
    const unreadMetaConversationIds = await metaApi.messages.getUnreadConversationsIds(customerUuid);

    return unreadMetaConversationIds.map(it => ({
      channel: SupportedChatChannel.WHATSAPP,
      senderId: it.accountId,
      clientId: it.targetId,
    }));
  }

  fetchUnreadMessagesIds(conversationId: ChatConversationId): Promise<string[]> {
    const { senderId, clientId } = conversationId;
    return metaApi.messages.getUnreadMessagesIds(senderId, clientId);
  }

  markMessageAsRead(): Promise<void> {
    // NOTHING to do here - messages are automatically marked as read based on the last fetch time
    return Promise.resolve();
  }

  private mapMetaMessageToChatMessage(whatsappBusinessId: string, targetId: string, metaMessage: MetaMessage): ChatMessage {
    const attachments: ChatAttachment[] = [];
    if (metaMessage.mediaURL) {
      attachments.push({
        link: metaMessage.mediaURL,
        filename: metaMessage.fileName,
        caption: metaMessage.caption,
      });
    }

    return {
      channel: SupportedChatChannel.WHATSAPP,
      senderId: whatsappBusinessId,
      clientId: targetId,
      msgId: metaMessage.id,
      date: dateTimeService.formatTimestamp(metaMessage.createdAt, whatsappChatConfig.dateFormat),
      message: metaMessage.text,
      outgoing: metaMessage.direction === 'outgoing',
      payloadType: this.mapType(metaMessage.type),
      attachments,
      status: metaMessage.status,
      statusCode: 200,
      error: null,
      statusDetails: metaMessage.statusDetails,
    };
  }

  private mapType(messageType: MetaMessageType): ChatMessagePayloadType {
    return this.msgTypeMappingTable[messageType] || ChatMessagePayloadType.UNSUPPORTED;
  }
}
