import { HttpClient } from '../common/httpClient';
import { PhoneNumber } from '../../types/meta/metaAccount';

type WhatsappPhoneNumberResponse = {
  readonly name?: string;
  readonly phoneNumber?: string;
  readonly phoneNumberId: string;
  readonly isActive?: boolean;
  readonly isFetched?: boolean;
  readonly businessAccountId: string;
  readonly externalId: string;
};

export class WhatsappPhoneNumberApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToWhatsappPhoneNumber(response: WhatsappPhoneNumberResponse): PhoneNumber {
    return {
      businessAccountId: response.businessAccountId,
      externalId: response.externalId,
      phoneNumberId: response.phoneNumberId,
      name: response.name,
      phoneNumber: response.phoneNumber,
    };
  }

  getPhoneNumbersById(businessAccountId: string): Promise<PhoneNumber[]> {
    return this.httpClient.get<WhatsappPhoneNumberResponse[]>(`accounts/${businessAccountId}/phone_numbers`)
      .then(data => (
        data.map(it => this.mapToWhatsappPhoneNumber(it))
      ));
  }
}
