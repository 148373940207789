import { AnyAction } from 'redux';
import { Customer } from '../types/customer';
import { MetaAccount, PhoneNumber } from '../types/meta/metaAccount';

interface MetaState {
  customers: Customer[];
  configs: MetaAccount[];
  selectedConfig: Partial<MetaAccount>;
  configsLoading: boolean;
  defaultAccount: Partial<MetaAccount>;
  defaultAccountLoading: boolean;
  phoneNumbers: PhoneNumber[];
  phoneNumbersLoading: boolean;
  phoneNumbersDialogOpen: boolean;
}

const initialState: MetaState = {
  customers: [],
  configs: [],
  selectedConfig: {},
  configsLoading: false,
  defaultAccount: {},
  defaultAccountLoading: false,
  phoneNumbers: [],
  phoneNumbersLoading: false,
  phoneNumbersDialogOpen: false,
};

export default function reducer(state: MetaState = initialState, action: AnyAction): MetaState {
  switch (action.type) {
    case 'SET_META_ADMIN_CUSTOMERS': {
      return {
        ...state,
        customers: action.customers,
      };
    }
    case 'SET_META_ADMIN_CONFIGS': {
      return {
        ...state,
        configs: action.configs,
      };
    }
    case 'SET_META_ADMIN_SELECTED_CONFIG': {
      return {
        ...state,
        selectedConfig: action.config,
      };
    }
    case 'SET_META_CONFIGS_LOADING': {
      return {
        ...state,
        configsLoading: action.payload,
      };
    }
    case 'UPDATE_META_CONFIG': {
      const itemToReplace = {
        appId: action.payload.appId,
        name: action.payload.name,
        createdAt: action.payload.createdAt,
        channel: action.payload.channel,
        metaAppId: action.payload.metaAppId,
        businessAccountId: action.payload.businessAccountId,
        isDisabled: action.payload.isDisabled,
        updatedAt: action.payload.updatedAt,
        tenantId: action.payload.tenantId,
      };
      return {
        ...state,
        configs: [
          ...state.configs.filter((item) => item.businessAccountId !== action.payload.businessAccountId),
          itemToReplace,
        ],
      };
    }
    case 'SET_DEFAULT_META_CONFIG': {
      return {
        ...state,
        defaultAccount: action.defaultAccount,
      };
    }
    case 'SET_DEFAULT_META_CONFIG_LOADING': {
      return {
        ...state,
        defaultAccountLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_PHONE_NUMBERS': {
      return {
        ...state,
        phoneNumbers: action.phoneNumbers,
      };
    }
    case 'SET_WHATSAPP_PHONE_NUMBERS_LOADING': {
      return {
        ...state,
        phoneNumbersLoading: action.phoneNumbersLoading,
      };
    }
    case 'SET_WHATSAPP_PHONE_NUMBERS_DIALOG_OPEN': {
      return {
        ...state,
        phoneNumbersDialogOpen: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}

